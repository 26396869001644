import "react-image-gallery/styles/css/image-gallery.css";

import ImageGallery from "react-image-gallery";

const images = [
  {
    original:
      "https://www.webdesignmuseum.org/uploaded/timeline/yahoo/yahoo-1994.png",
  },
  {
    original:
      "https://www.webdesignmuseum.org/uploaded/timeline/microsoft/microsoft-1994.png",
  },
  {
    original:
      "https://www.webdesignmuseum.org/uploaded/timeline/apple/apple-1996.png",
  },
  {
    original:
      "https://www.webdesignmuseum.org/uploaded/fullscreen/pepsi-1996.png",
  },
  {
    original:
      "https://www.webdesignmuseum.org/uploaded/timeline/pizza-hut/pizza-hut-1997.png",
  },
  {
    original:
      "https://www.webdesignmuseum.org/uploaded/timeline/dell/dell-1996.png",
  },
  {
    original:
      "https://www.webdesignmuseum.org/uploaded/fullscreen/bestbuy-1997.png",
  },
  {
    original:
      "https://www.webdesignmuseum.org/uploaded/timeline/geocities/geocities-1996.png",
  },
];

export default function Gallery() {
  return (
    <ImageGallery
      items={images}
      showNav={false}
      showFullscreenButton={false}
      showPlayButton={false}
      slideDuration={0}
      slideInterval={5000}
      autoPlay
    />
  );
}
