import { useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";

import app from "./sourceText/App.txt";
import game from "./sourceText/Game.txt";
import home from "./sourceText/Home.txt";

export default function Source() {
  const [Home, setHome] = useState("");
  const [App, setApp] = useState("");
  const [Game, setGame] = useState("");

  const useFileToText = (
    file: string,
    setText: React.Dispatch<React.SetStateAction<string>>
  ) => {
    useEffect(() => {
      fetch(file)
        .then((x) => x.text())
        .then((x) => setText(x));
    });
  };

  useFileToText(home, setHome);
  useFileToText(app, setApp);
  useFileToText(game, setGame);

  return (
    <div>
      <h1>Home.tsx</h1>
      <SyntaxHighlighter language="typescript" style={vs}>
        {Home}
      </SyntaxHighlighter>
      <h1>App.tsx</h1>
      <SyntaxHighlighter language="typescript" style={vs}>
        {App}
      </SyntaxHighlighter>
      <h1>Game.tsx</h1>
      <SyntaxHighlighter language="typescript" style={vs}>
        {Game}
      </SyntaxHighlighter>
    </div>
  );
}
