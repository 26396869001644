import React, { useCallback, useEffect, useState } from "react";

const displayBigInt = (x: bigint) => {
  let pow = x.toString().length - 1;
  return pow > 10
    ? (Number(x / 10n ** BigInt(pow - 4)) / 1e4).toString() + `e${pow}`
    : x.toLocaleString("en-US");
};

export default function Game() {
  let [count, setCount] = useState(0n);
  let [levelsOwned, setLevelsOwned] = useState<Array<number>>([]);

  let levelVisible = levelsOwned.length;

  const growthPrecision = 2;
  const growthMult = 10 ** growthPrecision;
  let levelGrowth = (level: number, rate: number) =>
    BigInt(Math.trunc(rate ** levelsOwned[level - 1] * growthMult));

  let levelCPS = (level: number) =>
    (8n ** (BigInt(level) - 1n) * levelGrowth(level, 1.1)) / BigInt(growthMult);
  let levelCost = (level: number) =>
    (10n ** BigInt(level) * levelGrowth(level, 1.15)) / BigInt(growthMult);

  let countPerSec = levelsOwned.reduce(
    (p, x, i) => BigInt(p) + BigInt(x) * levelCPS(i + 1),
    0n
  );

  let checkLevelVisible = useCallback(() => {
    if ((count + 1n) * 2n > 10n ** BigInt(levelsOwned.length + 1)) {
      setLevelsOwned((x) => [...x, 0]);
    }
  }, [count, levelsOwned.length]);

  let onClick = (_: React.MouseEvent) => {
    checkLevelVisible();
    setCount((x) => x + 1n);
  };
  let onClickCheat = (_: React.MouseEvent) => {
    checkLevelVisible();
    setCount((x) => x * 10n);
  };
  let onClickLevel = (_: React.MouseEvent, level: number) => {
    let cost = levelCost(level);
    if (count >= cost) {
      let owned = levelsOwned[level - 1] + 1;
      setLevelsOwned((x) => {
        x[level - 1] = owned;
        return x;
      });
      setCount((x) => x - cost);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkLevelVisible();
      setCount((x) => x + countPerSec);
    }, 1000);
    return () => clearInterval(interval);
  }, [count, countPerSec]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "900px",
        height: "600px",
        margin: "auto",
      }}
    >
      <div
        style={{
          flex: 1,
          flexBasis: "60%",
          flexGrow: 0,
          overflow: "auto",
          textAlign: "center",
        }}
      >
        <div style={{ height: "10%" }} />
        <h1>{displayBigInt(count)}</h1>
        <h2>{countPerSec > 0 ? `+${displayBigInt(countPerSec)}/s` : ""}</h2>
        <button style={{ height: "20%", width: "25%" }} onClick={onClick}>
          <p>Click</p>
        </button>
        <div style={{ width: "100%", height: "20px" }} />
        <button
          style={{ opacity: `${count ** 3n / 10n ** 11n}%` }}
          onClick={onClickCheat}
        >
          <p>Cheat</p>
        </button>
      </div>
      <div
        style={{
          flex: 1,
          flexBasis: "40%",
          flexDirection: "column",
          textAlign: "center",
          overflow: "auto",
        }}
      >
        {Array.from(Array(levelVisible + 1).keys())
          .slice(1)
          .map((level) => {
            return (
              <button
                key={level}
                style={{ height: "20%", width: "90%", margin: "10px" }}
                onClick={(e) => onClickLevel(e, level)}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ alignSelf: "flex-start" }}>
                    <h3 style={{ padding: "10px" }}>Level {level}</h3>
                    <h4>+{displayBigInt(levelCPS(level))}/s</h4>
                  </div>

                  <div style={{ alignSelf: "flex-end" }}>
                    <h4>x{levelsOwned[level - 1].toLocaleString("en-US")}</h4>
                    <h4 style={{ padding: "10px" }}>
                      Cost: {displayBigInt(levelCost(level))}
                    </h4>
                  </div>
                </div>
              </button>
            );
          })}
      </div>
    </div>
  );
}
